@import "./stm-common-variables";
@import "./stm-theme.scss";
@import "../../node_modules/primeng/resources/primeng.min.css";
@import "../../node_modules/primeicons/primeicons.css";
@import "../../node_modules/primeflex/primeflex.css";

/*****************************/
// COMMON PRIME NG OVERRIDES
/*****************************/
app-root {
  .p-datatable-loading-overlay {
    background-image: url("../assets/img/STMLoadingIcon.svg");
    background-repeat: no-repeat;
    background-color: rgb(250, 250, 250, 0.5);
    background-position: center;
    background-size: 25%;

    .p-datatable-loading-icon {
      display: none;
    }
  }
}

/*****************************/
// OTHER COMMON STYLES
/*****************************/
body {
  margin: 0;
  padding: 9rem 3rem;
  background-color: #ffffff;
  font-family: $stmFont;

  [ng-reflect-router-link] {
    cursor: pointer;
  }
}

.stm-red-text {
  color: $stmRed;
}

.stm-blue-text {
  color: $stmBlue;
}

.stm-header-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);

  .stm-header {
    padding: 0.3rem;
    background: #f4f4f4;
    margin: 0;
    font-weight: 600;
    color: $stmBlue;

    .p-button-danger {
      margin-right: 3rem;
      border-radius: 7px;

      span {
        color: white;
        font-weight: bold;
        padding-top: .05rem;
      }
    }

    .user-menu {
      top: 4rem !important;
      right: .8rem !important;
      width: 16rem;
      left: initial !important;
    }

    .p-menu {
      .p-submenu-header {
        color: $stmBlue;
      }
    }
    .p-menuitem-link {
      .p-menuitem-text {
        color: $stmBlue;
      }
    }

    .p-button-label, .p-menuitem {
      color: $stmBlue;
      font-weight: 800;
      margin-top: -.3rem;
    }

    .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: $stmBlue;
      :hover {
        color: $stmBlue;
      }
    }

    .p-button.p-button-text {
      color: $stmBlue;
    }

    .p-menu .p-menuitem-link .p-menuitem-icon {
      color: $stmBlue;
      :hover {
        color: $stmBlue;
      }
    }

    .p-menu .p-menuitem-link {
      :hover {
        color: $stmBlue;
      }
    }
  }

  .stm-nav {
    background-color: $stmBlue;
    height: 3rem;
    margin-top: 0;
  }

  .stm-nav > span {
    color: #ffffff;
    font-weight: bolder;
    margin: 0 1rem;
    cursor: pointer;
  }
}
