$stmRed: #da291c;
$stmBlue: #001489;
$stmFont: Arial, Helvetica, sans-serif;

// TODO: get rid of this once we get
// super-input and  text-badge components ported over to new style structure
/* Manually set/adjust custom component color variables here until PRIMENG exports theirs  */
$primary: #007ad9;
$secondary: #f4f4f4;
$success: #34a835;
$info: #007ad9;
$warning: #ffba01;
$danger: #e91224;
$dark: #333;

